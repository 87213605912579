import React from 'react';
import { Box, Typography, Link } from "@mui/material";

export function LandingPage() {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box m={6}>
        <Typography variant="h5" gutterBottom align="left">
          <b>Integration Hub</b>
        </Typography>
        <Typography variant="string" gutterBottom align="left">
          <br />
          The Clearinghouse is a statutorily-mandated program designed to facilitate the diversion of ineligible applicants and existing policyholders from the corporation into the voluntary insurance market.
          <br />
          <br />
        </Typography>
      </Box>
      <Box display={"flex"}>
        <Typography variant="body2" align="center">
          {" "}
          <br />
          {"© "} {new Date().getFullYear()}{" "}
          <Link href="https://www.citizensfla.org/" target="_blank">
            Citizens Property Insurance Corporation
          </Link>
          {"."}
        </Typography>
      </Box>
    </Box>
  );
}