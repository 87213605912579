import React, {useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import {useNavigate} from "react-router-dom";
import {MDialog} from "citizens-react-components";
function QuoteRequestDialog(props) {
  const [open, setOpen] = useState(props.openState);
  const [requestObj, setRequestObj] = useState(props?.jsonToUse?.Request || {});
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleCloseDialog(false);
  };

  const handleCheckEligibility = () => {
    setOpen(false);
    props.handleCloseDialog(false);
    const componentState = {
      requestId: props.viewData?.id,
      mitRoofCover:
          props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.dwelling
              ?.mitRoofCover || null,
      mitOpenProt:
          props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.dwelling
              ?.mitOpenProt || null,
      policyType:
          props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.policyType || null,
    };
    localStorage.setItem("items", JSON.stringify(componentState));
    navigate("/carrier-form");
  };

  return (
        <MDialog open={props.openState} handleClose={handleClose}
                 title={`ID: ${props.viewData?.id || ""}`}
                 body={
                   <Typography gutterBottom>
                     <ReactJson src={props?.jsonToUse || {}}/>
                   </Typography>
                 }
                 footerLeftComponent={props.jsonToUse?.Request !== null && (
                     <Button
                         autoFocus
                         onClick={handleCheckEligibility}
                         variant="contained"
                     >
                       Check Eligibility
                     </Button>
                 )}
        />
  );
}

export default QuoteRequestDialog;
