import React, {useEffect, useState} from "react";
import SearchCriteria from "./SearchCriteria";
import {parseError} from "../../config/react-routing";
import Loader from "../general/Loader";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import AppService from "../../service/AppService";
import {ExportToCsv} from "export-to-csv";
import QuoteRequestDialog from "./QuoteRequestDialog";
import {MReactTable} from "citizens-react-components";
import {Button, MenuItem} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function QuoteRequestPage(props) {
    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [jsonData, setJsonData] = useState({});
    const headers = [
        {
            header: "REQUEST ID",
            accessorKey: "id",
        },
        {
            header: "PROPERTY ADDRESS",
            accessorKey: "address",
            Cell: (rowData) => {
                return <div>{buildAddress(rowData?.row?.original)}</div>;
            },
        },
        {
            header: "PRODUCER CODE",
            accessorKey: "producerCode",
        },
        {
            header: "CREATED ON",
            accessorKey: "createdAt",
            Cell: ({cell}) => {
                return (
                    <div>{new Date(cell.getValue())?.toISOString()?.split("T")[0]}</div>
                );
            },
        },
    ];

    const csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers.map((c) => c.header),
    };
    const [searchForm, setSearchForm] = useState({
        zip: "",
        county: "",
        producerCode: "",
        createdAfter: "",
        createdBefore: "",
        createdAfterDate: "",
        createdBeforeDate: "",
    });
    const {execute, isLoading} = useFetchWithMsal(loginRequest);

    const csvExporter = new ExportToCsv(csvOptions);
    useEffect(() => {
        localStorage.setItem("items", JSON.stringify({}));
    }, []);

    const getRequestDetails = async (requestId) => {
        let jsonObject = jsonData;
        await execute(
            "POST",
            "/api/get-quote-request",
            JSON.stringify(Object.assign({}, {requestId: requestId}))
        )
            .then((requestBody) => {
                if (requestBody) {
                    jsonObject["Request"] = JSON.parse(requestBody);
                    setJsonData(jsonObject);
                }
            })
            .catch((err) => {
                jsonObject["Request"] = null;
                setJsonData(jsonObject);
            });
    };

    const getResponseDetails = async (requestId) => {
        let jsonObject = jsonData;
        await execute(
            "POST",
            "/api/get-quote-response",
            JSON.stringify(Object.assign({}, {requestId: requestId}))
        )
            .then((responseBody) => {
                jsonObject["Response"] = responseBody || {};
                setJsonData(jsonObject);
            })
            .catch((err) => {
                jsonObject["Response"] = null;
                setJsonData(jsonObject);
            });
    };

    const getCarrierDetails = async (requestId) => {
        let jsonObject = jsonData;
        await execute(
            "POST",
            "/api/get-quote-eligibility",
            JSON.stringify(Object.assign({}, {requestId: requestId}))
        )
            .then((carrierObj) => {
                jsonObject["carrierRequest"] = carrierObj?.requestObj;
                jsonObject["carrierResponse"] = carrierObj?.responseObj;
                setJsonData(jsonObject);
            })
            .catch((err) => {
                jsonObject["carrierRequest"] = null;
                jsonObject["carrierResponse"] = null;
                setJsonData(jsonObject);
            });
    };


    const handleViewClick = async (rowData) => {
        const requestId = rowData?.id;
        if (requestId) {
            await getRequestDetails(requestId);
            await getResponseDetails(requestId);
            await getCarrierDetails(requestId);
            setOpenDialog(true);
            setSelectedRowData(rowData);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const getDetails = async (criteria) => {
        setError(null);
        let searchCriteria = Object.assign({}, criteria);
        if (searchCriteria.createdAfterDate && searchCriteria.createdBeforeDate) {
            searchCriteria.createdAfter = searchCriteria.createdAfterDate + " 00:00:00.000"
            searchCriteria.createdBefore =
                searchCriteria.createdBeforeDate + " 23:59:59.000"
        }
        delete searchCriteria.createdAfterDate;
        delete searchCriteria.createdBeforeDate;
        AppService().getQuoteSearchResults(
            execute,
            JSON.stringify(searchCriteria)
        )
            .then((data) => {
                setTableData(data?.searchResult);
                setTotalRecords(data?.numOfRecords);
                setPageNumber(data?.pageNumber);
                setSearchForm({
                    ...searchForm,
                    pageNumber: data?.pageNumber,
                    token: data?.token,
                });
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get search results",
                    text: parseError(err),
                    showConfirmButton: true,
                });
            });
    };

    const handleReset = () => {
        setSearchForm({
            zip: "",
            county: "",
            producerCode: "",
            createdAfter: "",
            createdBefore: "",
            createdAfterDate: "",
            createdBeforeDate: "",
        });
        setTableData([]);
        setTotalRecords(0);
        setPageNumber(0);
        setError(null);
    };

    const nextPage = async (newPage) => {
        setPageNumber(newPage);
        getDetails({...searchForm, pageNumber: newPage});
    };

    const buildAddress = (row) => {
        let result = row?.address1;
        if (row?.address2) {
            result = result + " " + row?.address2;
        }
        return result + " " + row?.city + " " + row?.state + " " + row?.zip;
    };

    return (
        <Box display="flex" flexDirection="column" overflow="auto">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "22px",
                    fontWeight: 700,
                    marginBottom: "1rem",
                }}
            >
                Search Requests:
            </div>
            <SearchCriteria
                handleGetSearchResults={getDetails}
                setRows={setTableData}
                searchForm={searchForm}
                setSearchForm={setSearchForm}
                handleReset={handleReset}
            />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                }}
            >
                {isLoading && <Loader/>}
            </div>
            {!error && !isLoading && tableData?.length > 0 && (
                <div>
                    <QuoteRequestDialog
                        openState={openDialog}
                        viewData={selectedRowData}
                        handleCloseDialog={handleClose}
                        jsonToUse={jsonData}
                    />
                    <MReactTable
                        columns={headers}
                        data={tableData}
                        handleChangePage={nextPage}
                        rowCount={totalRecords}
                        pageNumberToUse={pageNumber}
                        enablePagination={true}
                        enableRowActions={true}
                        renderRowActionMenuItems={({row}) => [
                            <MenuItem key="view" onClick={() => handleViewClick(row.original)}>
                                View
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({table}) => (
                            <Box
                                sx={{display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap"}}
                            >
                                <Button
                                    disabled={table.getRowModel().rows?.length === 0}
                                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                    onClick={() => handleExportRows(table.getRowModel().rows)}
                                    startIcon={<FileDownloadIcon/>}
                                    variant="contained"
                                >
                                    Export Page Rows
                                </Button>
                            </Box>
                        )}
                    />
                </div>
            )}
        </Box>
    );
}
