import {Box, Button, Card, CardContent, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from 'react';
import CompareCarriersWithCitizensMenu from './CompareCarriersWithCitizensMenu';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {Alert} from "@mui/lab";
import {MDialog, MModal} from "citizens-react-components";
import {formatCurrency, scanAndFormatNumbers, StyledTableCell, StyledTableRow} from "../../util/constants";

export default function App(props) {
    const [carrierRequestAndResponse, setCarrierRequestAndResponse] = useState([]);
    const [quotePremium, setQuotePremium] = useState();
    const [isEligibleToCitizens, setIsEligibleToCitizens] = useState(false);
    const [isPolicyOverridden, setIsPolicyOverridden] = useState(false);
    const [policyOverriddenBy, setPolicyOverriddenBy] = useState();
    const [policyOverriddenAt, setPolicyOverriddenAt] = useState();
    const [openCarrierInfo, setOpenCarrierInfo] = React.useState(false);
    const [openCitizensPremiumInfo, setOpenCitizensPremiumInfo] = React.useState(false);
    const [carrierMoreContent, setCarrierMoreContent] = useState();
    const [citizensCompareContent, setCitizensCompareContent] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handleOpenCarrierMore = (selectedCarrierInfo) => {
        setCarrierMoreContent(selectedCarrierInfo);
        setOpenCarrierInfo(true);
    };

    const handleCitizensCompareContent = (selectedCitizensPremiumInfo) => {
        setCitizensCompareContent(selectedCitizensPremiumInfo);
        setOpenCitizensPremiumInfo(true);
    };
    const handleClose = () => {
        setCarrierMoreContent(undefined);
        setCitizensCompareContent(undefined);
        setOpenCarrierInfo(false);
        setOpenCitizensPremiumInfo(false);
    }

    useEffect(() => {
        if (props && props.data) {
            if (props.data.carrierRequestAndResponse && props.data.premiumResponse) {
                setCarrierRequestAndResponse(props.data.carrierRequestAndResponse);
                setQuotePremium(props.data.premiumResponse[0]);
                setIsEligibleToCitizens(props.data.eligibleToCitizens);
                setIsPolicyOverridden(props.data.policyOverridden);
                setPolicyOverriddenBy(props.data.policyOverriddenBy);
                setPolicyOverriddenAt(new Date(props.data.policyOverriddenAt));
            } else {
                setErrorMessage(props.data.errorMsg);
            }
        }
    }, [props]);


    return (<div> {errorMessage &&
        <Alert key={Math.random()}
               sx={{width: 'fit-content', textWrap: 'nowrap', marginTop: '1rem', marginBottom: '1rem',}}
               severity="warning">
            {errorMessage}</Alert>}
        <MDialog
            open={openCarrierInfo}
            handleClose={handleClose}
            body={
                    <Box>
                        {carrierMoreContent &&
                            <CompareCarriersWithCitizensMenu carrierData={carrierMoreContent}
                                                             toggleOpenClose={setOpenCarrierInfo}
                                                             quotePremium={quotePremium}/>}
                </Box>
            }
        />
        <MModal
            open={openCitizensPremiumInfo}
            handleClose={handleClose}
            body={
                <Box>
                        {(citizensCompareContent && citizensCompareContent) &&
                            <Box>
                                <TableContainer component={Paper} sx={{
                                    alignItems: 'center',
                                    verticalAlign: "middle"
                                }}>
                                    <Table sx={{minWidth: 200}} size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow style={{
                                                backgroundColor: "#0066a1 !important",
                                                background: "#0066a1 !important"
                                            }}>
                                                <StyledTableCell align="left">Citizens</StyledTableCell>
                                                <StyledTableCell align="inherit">Carrier</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Citizens
                                                    Premium: {formatCurrency(quotePremium.adjustedPrem)}</StyledTableCell>
                                                <StyledTableCell align="left" rowSpan={3}>Carrier
                                                    Response: {Object.entries(citizensCompareContent.message).map(([key, value]) => (
                                                        <div>{scanAndFormatNumbers(value)}</div>))}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Citizens Policy Holder
                                                    Surcharge: {formatCurrency(quotePremium.policyHolderSurcharge)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Citizens Emergency
                                                    Assessment: {formatCurrency(quotePremium.emergencyAssessment)}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                </Box>
            }
        />
        {!errorMessage &&
            <Box sx={{display: 'flex', gap: 2, marginTop: '1rem', marginBottom: '1rem', flexWrap: 'wrap'}}>
                {carrierRequestAndResponse && (
                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                        {isEligibleToCitizens &&
                            (<Alert key={Math.random()} sx={{width: 'fit-content', textWrap: 'nowrap'}} severity="info">
                                Eligible for Citizens</Alert>)}
                        {!isEligibleToCitizens &&
                            (<Alert key={Math.random()}
                                    sx={{width: 'fit-content', textWrap: 'nowrap', marginLeft: '1rem'}} severity="info">
                                Ineligible for Citizens</Alert>)}
                        {isPolicyOverridden &&
                            (<Alert key={Math.random()}
                                    sx={{width: 'fit-content', textWrap: 'nowrap', marginLeft: '1rem'}} severity="info">
                                Quote Overridden {policyOverriddenBy && (
                                <span>by {policyOverriddenBy}</span>)}
                                {policyOverriddenAt && (
                                    <span>&nbsp; at {policyOverriddenAt.toLocaleDateString()} &nbsp; {policyOverriddenAt.toLocaleTimeString()}</span>
                                )}</Alert>)}
                        {(!isPolicyOverridden && !isEligibleToCitizens) && (
                            <Button
                                variant="contained"
                                style={{width: '20%', background: "green", color: "#fff", marginLeft: "1rem"}}
                                onClick={props?.handleOverrideEligibility}
                            >
                                Override Eligibility
                            </Button>)}
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '1rem'
                        }}>
                            <Box
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'green',
                                    marginRight: '0.5rem'
                                }}
                            />
                            <Typography sx={{marginRight: '1rem'}}>Carrier Ineligible for Citizens</Typography>
                            <Box
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    marginRight: '0.5rem'
                                }}
                            />
                            <Typography>Carrier Eligible for Citizens</Typography>
                        </Box>
                    </Box>
                )}
                {carrierRequestAndResponse && (
                    carrierRequestAndResponse.map((requestAndResponse, index) => (
                        <Card variant="outlined"
                              sx={{
                                  minWidth: 275,
                                  border: '2px solid',
                                  borderColor: requestAndResponse.carrierResponse?.isSelected ? 'green' : 'red'
                              }}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h5" component="div">
                                        {requestAndResponse.carrierInfo.carrierName}
                                    </Typography>
                                </Box>
                                <Typography sx={{mb: 1.5}} color="text.secondary">
                                    Carrier Naic Code: {requestAndResponse.carrierInfo.carrierNaicCode}
                                </Typography>
                                <Typography variant="h6" component="div">
                                    Premium: {formatCurrency(requestAndResponse.carrierInfo.totalPrem)}
                                </Typography>
                            </CardContent>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                <Button size="small"
                                        onClick={() => handleOpenCarrierMore(requestAndResponse)}>Compare with
                                    Citizens</Button>
                                <Button size="small"
                                        onClick={() => handleCitizensCompareContent(requestAndResponse.carrierResponse)}
                                >More</Button>
                            </Box>
                        </Card>
                    ))
                )}
            </Box>}</div>)
}