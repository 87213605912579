import Grid from "@mui/material/Grid";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import Loader from "../general/Loader";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {Button, MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AppService from "../../service/AppService";
import {centerStyle} from "../../util/constants";
import {MModal, MReactTable} from "citizens-react-components";
import ReactJson from "react18-json-view";
import Typography from "@mui/material/Typography";

const VendorRenewalConfigurationView = () => {

    const headerColumns = [
        {
            header: "VENDOR NAME",
            accessorKey: "vendorName",
        },
        {
            header: "VENDOR ID",
            accessorKey: "uuid"
        },
        {
            header: "AUTH TYPE",
            accessorKey: "authType",
        },
        {
            header: "CARRIER ID",
            accessorKey: "carrierId",
        },
        {
            header: "CARRIER NAME",
            accessorKey: "carrierName",
        },
        {
            header: "FILTER CRITERIA",
            accessorKey: "configurationRequest",
            Cell: (rowData) => {
                return <FontAwesomeIcon icon={faEye} size="lg" onClick={() => handleViewClick(rowData)}/>
            }
        }
    ];
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [jsonData, setJsonData] = useState({});
    const [tableData, setTableData] = React.useState([]);
    const {execute, isLoading, isTokenAvailable} = useFetchWithMsal(loginRequest);
    const [vendorRenewalErrorMessage, setVendorRenewalErrorMessage] = React.useState();
    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleViewClick = async (rowData) => {
        const requestId = rowData?.row?.original?.uuid;
        if (requestId) {
            setOpenDialog(true);
            setSelectedRowData(rowData?.row?.original);
            setJsonData(JSON.parse(rowData?.row?.original?.configurationRequest));
        }
    };
    useEffect(() => {
        if (isTokenAvailable) {
            getAllVendorRenewalConfiguration();
        }
    }, [isTokenAvailable]);

    const handleUpdateConfiguration = async (rowData) => {
        navigate('/vendor-renewal-config?uuid=' + rowData.uuid);
    };

    async function getAllVendorRenewalConfiguration() {
        AppService().getAllVendorConfigs(execute)
            .then((response) => {
                if (response instanceof Array) {
                    setVendorRenewalErrorMessage(undefined);
                    if (response.length > 0) {
                        setTableData(response);
                    } else {
                        setVendorRenewalErrorMessage('No Vendor Configuration is Created.');
                    }
                } else {
                    setVendorRenewalErrorMessage('Unable to fetch Vendor Renewal Configuration at this time.');
                }
            })
            .catch((err) => {
                console.error(err);
                setVendorRenewalErrorMessage('Unable to fetch Vendor Renewal Configuration at this time.');
            });
    }

    return (<Grid>
        <div style={centerStyle}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    onClick={() => navigate('/vendor-renewal-config')}
                >
                    Create Vendor Renewal Configuration
                </Button>
            </Grid>

        </div>
        <div style={centerStyle}><Grid item lg={12}>
            {isLoading && <Loader/>}
        </Grid>
        </div>
        {!isLoading && tableData.length > 0 &&
            <div>
                <h2 style={centerStyle}>Vendor Renewal Configuration</h2>
               <MModal open={openDialog} handleClose={handleClose}
               body={
                   <Typography gutterBottom>
                   <ReactJson src={selectedRowData?.jsonToUse || {}}/>
               </Typography>}
               title={`Vendor Name: ${selectedRowData.viewData?.vendorName || ""}`}/>
                <MReactTable
                    columns={headerColumns}
                    data={tableData}
                    enableRowActions={true}
                    renderRowActionMenuItems={({row}) => [
                        <MenuItem key="update" onClick={() => handleUpdateConfiguration(row.original)}>
                            Update Configuration
                        </MenuItem>
                    ]}
                />
            </div>}
    </Grid>)
}

export default VendorRenewalConfigurationView;