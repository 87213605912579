import {Controller, useForm} from "react-hook-form";
import {Button, Card, FormControlLabel, InputAdornment, OutlinedInput, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import CustomizedSnackbars from "./Snackbar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Loader from "../general/Loader";
import Paper from "@mui/material/Paper";
import {
    BASIC,
    FIELD,
    filterCriteriaFields,
    filterCriteriaOperations,
    OAUTH2,
    OPERATION_TYPE,
    VALUE
} from "../../util/constants";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import AppService from "../../service/AppService";
import {MModal} from "citizens-react-components";

const buttonStyle = {
    marginLeft: '3rem'
}

const VendorRenewalConfigurationForm = () => {
    const {execute, isLoading, isTokenAvailable} = useFetchWithMsal(loginRequest);
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);

    const [apiSubmissionError, setApiSubmissionError] = React.useState(undefined);

    const [isJsonNotValid, setJsonNotValid] = React.useState(false);

    const [openModal, setOpenModal] = React.useState(false);
    const [criteria, setCriteria] = React.useState([]);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        navigate('/vendor-renewal-view');
    }

    const [token, setToken] = useState(undefined);

    const location = useLocation();

    const [uuid, setUuid] = React.useState();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [mode, setMode] = React.useState('create');

    useEffect(() => {
        if (isTokenAvailable) {
            const uuid = new URLSearchParams(location.search).get('uuid');
            if (uuid) {
                setMode('update');
                setUuid(uuid);
                handleGetConfigurationData(uuid);
            } else {
                setMode('create');
            }
        }
    }, [isTokenAvailable]);

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: {errors}
    } = useForm();

    const authenticationTypeWatchValue = watch('authType');

    async function handleGetConfigurationData(uuid) {
        AppService().getVendorConfigForAGivenUuid(execute, uuid)
            .then((response) => {
                setApiSubmissionError(undefined);
                if (response?.status === 'fail') {
                    setApiSubmissionError(response?.errorMsg);
                } else if (response instanceof Array && response.length > 0) {
                    const configuration = response[0];
                    setValue('vendorName', configuration.vendorName);
                    setValue('postUrl', configuration.postUrl);
                    setValue('active', configuration.active);
                    setValue('carrierId', configuration.carrierId);
                    setValue('carrierName', configuration.carrierName);
                    setValue('override', configuration.override);
                    setValue('authType', configuration.authType);
                    setValue('headerKey', configuration.headerKey);
                    setValue('headerValue', configuration.headerValue);

                    if (configuration.authType === BASIC) {
                        setValue('username', configuration.username);
                        setValue('password', configuration.password);
                    } else {
                        setValue('clientId', configuration.clientId);
                        setValue('clientSecret', configuration.clientSecret);
                        setValue('scope', configuration.scope);
                        setValue('grantType', configuration.grantType);
                        setValue('tokenUri', configuration.tokenUri);
                    }
                    if (configuration.filterCriteria && configuration.filterCriteria.length > 0) {
                        let filterCriteria = configuration.filterCriteria;
                        filterCriteria = filterCriteria.map(criteria => {
                            criteria.value = criteria.value.join(',');
                            return criteria;
                        });
                        setCriteria(filterCriteria);
                    }
                } else {
                    setApiSubmissionError('No Configuration exists for the given uuid' + uuid);
                }
            })
            .catch((err) => {
                console.error('Api submission error', err.message);
                setApiSubmissionError(JSON.stringify(err.message));
            });
    }

    const centerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "1rem",
    };

    async function handleSubmission(data) {
        let payload = JSON.parse(JSON.stringify(data));
        let criteriaCopy = JSON.parse(JSON.stringify(criteria));
        payload.configurationRequest = criteriaCopy.map(criteria => {
            criteria.value = criteria.value.split(",");
            return criteria;
        });
        if (authenticationTypeWatchValue === OAUTH2) {
            payload['username'] = null;
            payload['password'] = null;
        } else if (authenticationTypeWatchValue === BASIC) {
            payload['clientId'] = null;
            payload['clientSecret'] = null;
            payload['scope'] = null;
            payload['grantType'] = null;
            payload['tokenUri'] = null;
        }
        delete payload[FIELD];
        delete payload[OPERATION_TYPE];
        delete payload[VALUE];
        execute(
            "POST",
            `/api/admin/policy-renewal-config-` + mode,
            JSON.stringify(payload)
        )
            .then((response) => {
                setApiSubmissionError(undefined);
                setToken(undefined);
                if (response?.status === 'fail') {
                    setApiSubmissionError(response?.errorMsg);
                } else {
                    setToken(response?.uuid);
                    reset();
                    setCriteria([]);
                    handleOpenModal();
                }
            })
            .catch((err) => {
                console.error('Api submission error', err.message);
                setApiSubmissionError(JSON.stringify(err.message));
            });
    }


    const watchValues = watch({
        control
    });
    useEffect(() => {
    }, [watchValues]);

    const removeCriteria = (indexToRemove) => {
        const newArray = criteria.filter((item, index) => index !== indexToRemove);
        setCriteria(newArray);
    };

    const appendCriteria = () => {
        setCriteria(prevState => [...prevState, {
            field: getValues(FIELD),
            operationType: getValues(OPERATION_TYPE),
            value: getValues(VALUE)
        }]);
        setValue(FIELD, '');
        setValue(OPERATION_TYPE, '');
        setValue(VALUE, '');

    };

    const validateAllCriteriaFieldsAreEmpty = () => {
        return !(getValues(FIELD) !== '' && getValues(OPERATION_TYPE) !== '' && getValues(VALUE) !== '');
    }


    return (<Grid>
            {!isLoading &&
                <form
                    onSubmit={handleSubmit(handleSubmission)}
                    style={{display: "flex", margin: "1rem"}}
                >
                    <Grid container spacing={2}>
                        {uuid &&
                            <Grid item xs={12}>
                                <Controller name="uuid"
                                            defaultValue={uuid}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="Vendor UUID"
                                                    disabled
                                                    error={!!errors['uuid']}
                                                    helperText={errors["uuid"] && `${errors["uuid"].message}`}
                                                />)}></Controller>
                            </Grid>}
                        {/*Line Break*/}
                        <Grid item xs={6}>
                            <Controller name="vendorName"
                                        defaultValue=""
                                        control={control}
                                        rules={{
                                            required: 'This field is required', minLength: {
                                                message: 'Min of 3 characters', value: 3
                                            }, maxLength: {
                                                message: 'Maximum of 25 characters', value: 25
                                            }
                                        }}
                                        render={({field}) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                label="Vendor Name"
                                                error={!!errors['vendorName']}
                                                helperText={errors["vendorName"] && `${errors["vendorName"].message}`}
                                            />)}></Controller>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller name="postUrl"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'This field is required', pattern: {
                                                value: /^(https?):\/\/[^\s/$.?#].[^\s]*$/, message: 'Enter a valid URL',
                                            }
                                        }}
                                        render={({field}) => (<TextField
                                            fullWidth
                                            {...field}
                                            label="Vendor Url"
                                            error={!!errors['postUrl']}
                                            helperText={errors["postUrl"] && `${errors["postUrl"].message}`}
                                        />)}></Controller>
                        </Grid>
                        {/*Line Break*/}
                        <Grid item xs={6}>
                            <Controller name="carrierId"
                                        defaultValue=""
                                        control={control}
                                        rules={{
                                            required: 'This field is required', minLength: {
                                                message: 'Min of 3 characters', value: 3
                                            },
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: 'Please enter a valid integer',
                                            }
                                        }}
                                        render={({field}) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                label="Carrier Id"
                                                error={!!errors['carrierId']}
                                                helperText={errors["carrierId"] && `${errors["carrierId"].message}`}
                                            />)}></Controller>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller name="carrierName"
                                        defaultValue=""
                                        control={control}
                                        rules={{
                                            required: 'This field is required', minLength: {
                                                message: 'Min of 3 characters', value: 3
                                            }
                                        }}
                                        render={({field}) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                label="Carrier Name"
                                                error={!!errors['carrierName']}
                                                helperText={errors["carrierName"] && `${errors["carrierName"].message}`}
                                            />)}></Controller>
                        </Grid>
                        {/*Line Break*/}
                        <Grid item xs={6}>
                            <Box>
                                <Controller name="override"
                                            defaultValue={true}
                                            control={control}
                                            rules={{
                                                required: 'Select at least one option',
                                            }}
                                            render={({field}) => (
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item><FormLabel>Override Vendor Response(carrier id and
                                                        carrier name) </FormLabel></Grid>
                                                    <Grid item>
                                                        <RadioGroup row {...field} defaultValue={false}>
                                                            <FormControlLabel control={<Radio value={true}/>}
                                                                              label="True"/>
                                                            <FormControlLabel control={<Radio value={false}/>}
                                                                              label="False"/>
                                                        </RadioGroup>
                                                    </Grid>
                                                    <FormHelperText>{errors["override"]?.message}</FormHelperText>
                                                </Grid>)}>
                                </Controller>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <Controller name="active"
                                            defaultValue={true}
                                            control={control}
                                            rules={{
                                                required: 'Select at least one option',
                                            }}
                                            render={({field}) => (
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item><FormLabel>Vendor Active: </FormLabel></Grid>
                                                    <Grid item>
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabel control={<Radio value={true}/>}
                                                                              label="True"/>
                                                            <FormControlLabel control={<Radio value={false}/>}
                                                                              label="False"/>
                                                        </RadioGroup>
                                                    </Grid>
                                                    <FormHelperText>{errors["active"]?.message}</FormHelperText>
                                                </Grid>)}>
                                </Controller>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller name="headerKey"
                                        defaultValue=""
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                label="Header Key(Optional)"
                                            />)}></Controller>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller name="headerValue"
                                        defaultValue=""
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                fullWidth
                                                {...field}
                                                label="Header Value(Optional)"
                                            />)}></Controller>
                        </Grid>
                        {/*Line Break*/}
                        <Grid item xs={4}>
                            <Box mb={2}>
                                <Controller name="authType"
                                            defaultValue={BASIC}
                                            control={control}
                                            rules={{
                                                required: 'Select one option',
                                            }}
                                            render={({field}) => (
                                                <div>
                                                    <FormLabel>Authentication Type:</FormLabel>
                                                    <Select {...field} style={{width: '10rem'}}>
                                                        <MenuItem value={BASIC}>Basic</MenuItem>
                                                        <MenuItem value={OAUTH2}>OAuth2</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errors["authType"]?.message}</FormHelperText>
                                                </div>)}>
                                </Controller>
                            </Box>
                        </Grid>
                        {(authenticationTypeWatchValue &&
                                authenticationTypeWatchValue.length > 0 &&
                                authenticationTypeWatchValue === OAUTH2) &&
                            <Grid item xs={6}>
                                <Box mb={2}>
                                    <Controller name="clientId"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required',
                                                    minLength: {
                                                        message: 'Min of 3 characters', value: 3
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        label="Client Id"
                                                        error={!!errors['clientId']}
                                                        helperText={errors["clientId"] && `${errors["clientId"].message}`}
                                                    />)}>
                                    </Controller></Box>
                                <Box mb={2}>
                                    <Controller name="clientSecret"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required',
                                                    minLength: {
                                                        message: 'Min of 3 characters', value: 3
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        label="Client Secret"
                                                        error={!!errors['clientSecret']}
                                                        helperText={errors["clientSecret"] && `${errors["clientSecret"].message}`}
                                                    />)}>
                                    </Controller></Box>
                                <Box mb={2}>
                                    <Controller name="scope"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required',
                                                    minLength: {
                                                        message: 'Min of 3 characters', value: 3
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        label="Scope"
                                                        error={!!errors['scope']}
                                                        helperText={errors["scope"] && `${errors["scope"].message}`}
                                                    />)}>
                                    </Controller>
                                </Box> <Box mb={2}>
                                <Controller name="grantType"
                                            defaultValue=""
                                            control={control}
                                            rules={{
                                                required: 'This field is required',
                                                minLength: {
                                                    message: 'Min of 3 characters', value: 3
                                                }, maxLength: {
                                                    message: 'Maximum of 25 characters',
                                                    value: 25
                                                }
                                            }}
                                            render={({field}) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    label="Grant Type"
                                                    error={!!errors['grantType']}
                                                    helperText={errors["grantType"] && `${errors["grantType"].message}`}
                                                />)}>
                                </Controller>
                            </Box>
                                <Box>
                                    <Controller name="tokenUri"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required',
                                                    minLength: {
                                                        message: 'Min of 3 characters', value: 3
                                                    },
                                                    pattern: {
                                                        value: /^(https?):\/\/[^\s/$.?#].[^\s]*$/,
                                                        message: 'Enter a valid URL',
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        label="Token Url"
                                                        error={!!errors['tokenUri']}
                                                        helperText={errors["tokenUri"] && `${errors["tokenUri"].message}`}
                                                    />)}>
                                    </Controller>
                                </Box>
                            </Grid>
                        }

                        {(authenticationTypeWatchValue &&
                                authenticationTypeWatchValue.length > 0 &&
                                authenticationTypeWatchValue === BASIC) &&
                            <Grid item xs={6}>
                                <Box mb={2}>
                                    <Controller name="username"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required',
                                                    minLength: {
                                                        message: 'Min of 3 characters', value: 3
                                                    }, maxLength: {
                                                        message: 'Maximum of 25 characters', value: 25
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <TextField
                                                        fullWidth
                                                        {...field}
                                                        label="Username"
                                                        error={!!errors['username']}
                                                        helperText={errors["username"] && `${errors["username"].message}`}
                                                    />)}>

                                    </Controller>
                                </Box>
                                <Box>
                                    <Controller name="password"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required: 'This field is required'
                                                }}
                                                render={({field}) => (
                                                    <div>
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            label="Password"
                                                            placeholder="Password"
                                                            {...field}
                                                            fullWidth
                                                            type={showPassword ? 'text' : 'password'}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff/> :
                                                                            <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        <FormLabel> {errors["password"] && errors["password"]?.message}</FormLabel>
                                                    </div>
                                                )}>
                                    </Controller>
                                </Box>
                            </Grid>
                        }
                        <Grid item xs={2}></Grid>
                        {/*Line Break*/}

                        <Grid item xs={3}>
                            <Card sx={{margin: '0.5rem', border: '1px solid #0066a1', padding: '0.5rem'}}>
                                <Controller
                                    defaultValue=""
                                    render={({field}) =>
                                        <div>
                                            <FormLabel>Field Type:</FormLabel>
                                            <Select {...field}
                                                    style={{minWidth: '15rem', margin: '0.5rem'}}>
                                                {filterCriteriaFields.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    }
                                    name={FIELD}
                                    control={control}
                                />
                                <Controller
                                    defaultValue=""
                                    render={({field}) =>
                                        <div>
                                            <FormLabel>Operation Type:</FormLabel>
                                            <Select {...field}
                                                    style={{minWidth: '15rem', margin: '0.5rem'}}>
                                                {filterCriteriaOperations.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>}
                                    name={OPERATION_TYPE}
                                    control={control}
                                />
                                <Controller
                                    defaultValue=""
                                    render={({field}) =>
                                        <div>
                                            <FormLabel>Field Value: must be comma(,) separated</FormLabel>
                                            <TextField
                                                style={{minWidth: '15rem', margin: '0.5rem'}}
                                                {...field}
                                                label="Values"
                                            />
                                        </div>}
                                    name={VALUE}
                                    control={control}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    size="large"
                                    fullWidth
                                    disabled={validateAllCriteriaFieldsAreEmpty()}
                                    onClick={() => appendCriteria()}
                                >
                                    Add Filter Criteria </Button>
                            </Card>
                        </Grid>
                        <Grid xs={1}></Grid>
                        <Grid xs={6}>
                            <Box mt={2} sx={{'& > *': {marginTop: '16px'}}}>
                                <FormLabel>Filter Criteria {
                                    criteria.length === 0 &&
                                    <FormLabel>is Empty.</FormLabel>
                                }
                                    <ul style={{listStyleType: 'none', padding: '1rem'}}>
                                        {criteria.map((item, index) => {
                                            return (
                                                <Box key={'filterCriteriaBox' + index} mb={2}>
                                                    <li key={'filterCriteria' + index}>
                                                        <Paper elevation={3}
                                                               style={{
                                                                   display: 'flex',
                                                                   justifyContent: 'space-between',
                                                                   alignItems: 'center',
                                                                   padding: '0.6rem',
                                                                   minWidth: 400,
                                                                   margin: 'auto'
                                                               }}>
                                                            <Typography
                                                                variant="h7" gutterBottom
                                                                style={{wordWrap: 'break-word'}}>
                                                                {item.field}
                                                                <span>&nbsp;&nbsp;</span>
                                                                {item.operationType}
                                                                <span>&nbsp;&nbsp;</span>
                                                                [{item.value}]
                                                            </Typography>
                                                            <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faTrash}
                                                                             size="lg"
                                                                             onClick={() => removeCriteria(index)}/>
                                                        </Paper>
                                                    </li>
                                                </Box>
                                            );
                                        })}
                                    </ul>
                                </FormLabel>
                            </Box>
                        </Grid>
                        <Grid xs={2}></Grid>
                        {/*Line Break*/}

                        <Grid item xs={4}>
                            <Button
                                disabled={Object.keys(errors).length > 0 || isJsonNotValid}
                                variant="contained"
                                color="primary"
                                type="submit"
                                size="large"
                            >{(mode === 'update') ? <span>Update</span> : <span>Create</span>} &nbsp; Vendor
                                Renewal Configuration
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={buttonStyle}
                                    component={Link}
                                    to="/vendor-renewal-view"
                                    variant="contained"
                                    color="primary">
                                View All Vendor Renewal Configuration
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            }

            {
                apiSubmissionError &&
                <CustomizedSnackbars message={apiSubmissionError} status="error"/>
            }
            {
                token && <MModal open={openModal}
                                 handleClose={handleCloseModal}
                                 title={"Vendor Identifier"}
                                 body={<div><p>
                                     {token}
                                 </p>
                                     <p>Kindly make a note of this token and securely store it for future updates to the
                                         configuration.</p></div>}/>
            }
            {
                isLoading && <div style={centerStyle}><Loader/></div>
            }
        </Grid>
    )
};

export default VendorRenewalConfigurationForm;