import Button from "@mui/material/Button";
import React, {useState} from "react";
import {AppointmentAuditDialog} from "./AppointmentAuditDialog";
import {Grid} from "@mui/material";
import Loader from "../general/Loader";
import {centerStyle} from "../../util/constants";
import {MReactTable} from "citizens-react-components";

export default function AppliedCarrierView(props) {

    const {appliedCarriers, isLoading} = props;
    const [selectedRow, setSelectedRow] = useState();
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRow(undefined);
    }

    const headers = [
        {
            header: "Carrier Name",
            accessorKey: "agentCarrierData.companyName",
            filtering: true
        },
        {
            header: "Audit",
            id: "Audit",
            Cell: (rowData) => {
                return <div>{rowData?.row?.original.agentAppointmentAuditData && rowData?.row?.original.agentAppointmentAuditData.length > 0 &&
                    (<Button
                        variant="outlined"
                        color="info"
                        onClick={() => handleOpenModal(rowData?.row?.original)}
                    >
                        {'View Audit'}
                    </Button>)
                }</div>
            },
        }]

    return (
        <Grid item xs={12}>
            {selectedRow &&
                <AppointmentAuditDialog dfsSearch="" open={openModal} onClose={handleCloseModal}
                                        data={selectedRow} carrierView={true}/>}
            {!isLoading && (appliedCarriers && (appliedCarriers.agentCarrierWrapperData) &&
                    (appliedCarriers.agentCarrierWrapperData.length > 0)) &&
                <MReactTable
                    columns={headers}
                    data={appliedCarriers.agentCarrierWrapperData}
                />}
        </Grid>);
}