import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, FormControlLabel, FormGroup, Paper, Switch, TextField, Typography} from "@mui/material";
import {formatDate, StyledTableCell, StyledTableRow} from "../../util/constants";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import InputLabel from "@mui/material/InputLabel";
import {MDialog, MReactTable} from "citizens-react-components";

export function AppointmentAuditDialog(props) {
    const {onClose, open, data, carrierView, dfsSearch} = props;
    const [feed, setFeed] = useState(data);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [showOverride, setShowOverride] = useState(false);

    useEffect(() => {
        if (carrierView) {
            showOverrideData(false);
        } else {
            if (data) {
                let feedData = JSON.parse(JSON.stringify(data));
                const dfsFilteredData = data.agentAppointmentAuditData
                    .filter(auditData => auditData.dfsLicense === dfsSearch);
                feedData.agentAppointmentAuditData = dfsFilteredData;
                setFeed(feedData);
            }
        }
    }, [data]);

    const showOverrideData = (isOverriden) => {
        let feedData = JSON.parse(JSON.stringify(data));
        if (!isOverriden) {
            const filteredAuditData = data.agentAppointmentAuditData.filter(auditData => auditData.isOverride === true);
            feedData.agentAppointmentAuditData = filteredAuditData;
        }
        setFeed(feedData);
    }

    const filteredData = useMemo(() => {
        if (!startDate || !endDate) return feed.agentAppointmentAuditData;

        const start = dayjs(startDate).valueOf();
        const end = dayjs(endDate).valueOf();

        return feed.agentAppointmentAuditData.filter((item) => {
            const updatedAt = item.updatedAt;
            return updatedAt >= start && updatedAt <= end;
        });
    }, [startDate, endDate, feed]);

    const columns = useMemo(
        () => {
            const columnList = [
                {
                    accessorKey: 'isOverride',
                    header: 'Override',
                    Cell: ({cell}) => (cell.getValue() ? 'Yes' : 'No'),
                },
                {
                    accessorKey: 'updatedAt',
                    header: 'Updated At',
                    Cell: ({cell}) => formatDate(cell.getValue()),
                },
                {
                    accessorKey: 'updatedBy',
                    header: 'Updated By',
                }
            ];
            if (carrierView) {
                columnList.unshift({
                    accessorKey: 'agentName',
                    header: 'Agent Name'
                });
                columnList.unshift({
                    accessorKey: 'dfsLicense',
                    header: 'DFS License'
                });
            }
            ;
            return columnList;
        }, []);

    const handleClose = () => {
        onClose();
    };


    useEffect(() => {
            if (carrierView)
                showOverrideData(showOverride);
        },
        [showOverride]
    );

    return (
        <MDialog open={open} handleClose={handleClose}
                 title={
                     <Box alignContent={"center"}>
                         <InputLabel sx={{backgroundColor: '#0066a1 !important', fontWeight: "bold", color: '#fff'}}>
                             CARRIER DETAILS
                         </InputLabel>
                         <Box sx={{display: 'flex', align: 'center'}}>
                             <TableContainer component={Paper} sx={{
                                 padding: '1rem',
                                 marginBottom: '0.5rem',
                                 alignItems: 'center',
                                 verticalAlign: "middle"
                             }}>
                                 <Table sx={{minWidth: 200, width: "80%"}} size="small" aria-label="customized table">
                                     <TableBody>
                                         <StyledTableRow>
                                             <StyledTableCell align="left">Carrier</StyledTableCell>
                                             <StyledTableCell textAlign="right" rowSpan={1}>
                                                 {feed?.agentCarrierData?.companyName}
                                             </StyledTableCell>
                                         </StyledTableRow>
                                         <StyledTableRow>
                                             <StyledTableCell align="left">Company Code</StyledTableCell>
                                             <StyledTableCell textAlign="right" rowSpan={1}>
                                                 {feed?.agentCarrierData?.companyCode}
                                             </StyledTableCell>
                                         </StyledTableRow>
                                         {!carrierView && (
                                             <StyledTableRow>
                                                 <StyledTableCell align="left">DFS License Number</StyledTableCell>
                                                 <StyledTableCell textAlign="right" rowSpan={1}>
                                                     {dfsSearch}
                                                 </StyledTableCell>
                                             </StyledTableRow>)}
                                         {!carrierView && (
                                             <StyledTableRow>
                                                 <StyledTableCell align="left">Override Current status</StyledTableCell>
                                                 <StyledTableCell textAlign="right" rowSpan={1}>
                                                     {feed?.agentCarrierData?.isOverride ? 'Yes' : 'No'}
                                                 </StyledTableCell>
                                             </StyledTableRow>
                                         )}
                                     </TableBody>
                                 </Table>
                             </TableContainer>
                         </Box>
                     </Box>
                 }
                 body={
                     <Box>
                         <Box mb={2}>
                             <Stack direction="row" spacing={4} alignItems="center">
                                 <Box>
                                     <label htmlFor="start-date">Start Date</label>
                                     <TextField
                                         id="start-date"
                                         type="datetime-local"
                                         value={startDate}
                                         onChange={(e) => setStartDate(e.target.value)}
                                         fullWidth
                                         InputLabelProps={{shrink: true}}
                                     />
                                 </Box>
                                 <Box>
                                     <label htmlFor="end-date">End Date</label>
                                     <TextField
                                         id="end-date"
                                         type="datetime-local"
                                         value={endDate}
                                         onChange={(e) => setEndDate(e.target.value)}
                                         fullWidth
                                         InputLabelProps={{shrink: true}}
                                     />
                                 </Box>
                                 <Button
                                     variant="contained"
                                     color="primary"
                                     onClick={() => {
                                         setStartDate('');
                                         setEndDate('');
                                     }}
                                 >
                                     Reset
                                 </Button>
                                 {carrierView &&
                                     <FormGroup>
                                         <FormControlLabel
                                             control={<Switch
                                                 checked={showOverride}
                                                 onChange={() => setShowOverride(!showOverride)}
                                                 inputProps={{'aria-label': 'controlled'}}

                                             />} label={showOverride ? 'Show Overridden Only' : 'Show All'}/>
                                     </FormGroup>
                                 }
                             </Stack>
                         </Box>
                         <MReactTable columns={columns}
                                      data={filteredData}
                                      renderTopToolbarCustomActions={() => (
                                          <Box>
                                              <Typography variant="h5">Override History</Typography>
                                          </Box>)}

                         /> </Box>}
        />
    );
}
